export default {
  get: function(){
    return {
      course:{},
      teacher:{},
      survey: {
        id: null,
        name: null,
        credits: null,
        completed: null,
        canComplete: null,
      },
      sections:[],
      api:{
        get:{
          fetching: null,
          error: false,
          values: {}
        },
        put: {
        },
        post:{
          answer:{
            sending: false
          }
        },
        delete:{
        }
      }
    }
  }
}
