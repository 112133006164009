/**
 * List of post endpoints
 */
const ENDPOINT = new Map();

ENDPOINT.set('fetch', '/course/survey/get');
ENDPOINT.set('getForUser', '/course/survey/user');
ENDPOINT.set('answer', '/course/survey/answer');

export default ENDPOINT
