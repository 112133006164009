const survey = state => state.survey;
const sections = state => state.sections;
const course = state => state.course;
const teacher = state => state.teacher;
const answering = state => state.api.post.answer.sending;
const completed = state => state.survey.completed;
const fetching = state => state.api.get.fetching;

export default {
  course,
  teacher,
  survey,
  sections,
  answering,
  completed,
  fetching
}
